import { useUpdateAccount } from "apollo/hooks/mutations";
import {
  useLazyProviderSearchActivation,
  useLazyProviderSearchLoginActivation,
} from "apollo/hooks/queries";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  REDIRECT_PARAM,
  REDIRECT_REASON_GENERIC_ERROR,
  REDIRECT_REASON_LINK_EXPIRED,
  REDIRECT_REASON_PARAM,
  RETURN_PARAM,
  SEALD_FLOW_CHALLENGE,
} from "core/consts";
import Config from "core/model/config";
import {
  getApolloNetworkErrorMessage,
  getErrorMessage,
} from "core/model/utils/errors";
import { useSealdContext } from "core/seald/SealdContext";
import {
  ProviderSearchActivation,
  QueryProgress,
  RedirectReason,
} from "core/types";
import SpinnerPage from "ds_legacy/components/Spinner";
import { useOnEnter } from "dsl/atoms/Routes";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { usePageMetaData, usePreventUnload } from "dsl/hooks";
import { useProvidersearchNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import { getProviderSearchTitle } from "dsl/molecules/ProviderSearchAppWrapper";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoggedInAccount } from "reduxentities/selectors";
import { useTranslations } from "translations";

export const WIZARD_TYPE_LOGIN = "login";
export const WIZARD_TYPE_ACTIVATION = "activate";

const WIZARD_TYPES = [WIZARD_TYPE_LOGIN, WIZARD_TYPE_ACTIVATION] as const;

type Wizard = (typeof WIZARD_TYPES)[number];

const getErrorTags = (accountId?: number) =>
  `${
    accountId ? `[account_id:${accountId}]` : ""
  }[seald_flow:3][challenge:yes}]`;

const useActivateProviderSearchAccount = ({
  wizardType,
}: {
  wizardType: Wizard;
}) => {
  const importSealdIdentity = useSealdContext()?.importSealdIdentity;
  const [updateAccount] = useUpdateAccount();
  const dispatch = useDispatch();
  const activateProviderSearchAccount = useLazyProviderSearchActivation();
  const loginProviderSearchAccount = useLazyProviderSearchLoginActivation();

  return async ({ challenge, token }: { challenge: string; token: string }) => {
    if (!importSealdIdentity) {
      throw new Error("no importSealdIdentity");
    }
    let activation: ProviderSearchActivation | undefined;
    try {
      if (wizardType === WIZARD_TYPE_ACTIVATION) {
        activation = await activateProviderSearchAccount(token);
      } else {
        activation = await loginProviderSearchAccount(token);
      }
    } catch (err) {
      throw new Error(
        `Login flow failed: ${getErrorTags()} - error activating provider search account - ${getErrorMessage(
          err,
        )} - ${getApolloNetworkErrorMessage(err)}`,
      );
    }

    const account = activation?.account;
    if (!account) {
      throw new Error(
        `Login flow failed:  ${getErrorTags()} - no account returned`,
      );
    }
    const authResult = activation?.auth_result;
    if (!authResult?.id_token) {
      throw new Error(
        `Login flow failed: ${getErrorTags(account.id)} - no id_token returned`,
      );
    }
    const twoManSessionId = activation?.seald_two_man_rule_session_id;
    if (!twoManSessionId) {
      throw new Error(
        `Login flow failed: ${getErrorTags(
          account.id,
        )} - no seald_two_man_rule_session_id returned`,
      );
    }

    try {
      const mustAuthenticate = await importSealdIdentity({
        account,
        challenge,
        password: undefined,
        seald_flow: SEALD_FLOW_CHALLENGE,
        token: authResult?.id_token,
        updateAccount,
        twoManSessionId,
      });
      if (mustAuthenticate) {
        throw new Error(`must authenticate returned true`);
      }
    } catch (err) {
      throw new Error(
        `Seald flow failed: ${getErrorTags(account.id)} ${getErrorMessage(
          err,
        )}`,
      );
    }

    const decoded = jwtDecode<{ exp: number }>(authResult?.id_token);

    dispatch({
      type: "TOKEN_CHANGED",
      payload: {
        auth_type: "passwordless",
        version: Config.version,
        token: authResult.id_token,
        token_type: "jwt",
        expiration: decoded?.exp,
        identification: {
          account,
          careseeker_roles: account.roles?.careseeker_roles,
        },
      },
    });
  };
};

const useGetLoginProps = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const challenge = searchParams.get("challenge");
  const redirectTo = searchParams.get(REDIRECT_PARAM) || "/listing";
  return {
    token,
    challenge,
    redirectTo,
  };
};

export default function ProviderSearchLoginWizard({
  wizardType,
}: {
  wizardType: Wizard;
}) {
  useOnEnter();
  const activateProviderSearch = useActivateProviderSearchAccount({
    wizardType,
  });
  const loggedAccount = useLoggedInAccount();
  const [loggedInState, setLoggedInState] = useState<{
    error: RedirectReason | null;
    progress: QueryProgress;
  }>({ progress: QUERY_PROGRESS_PENDING, error: null });
  const { challenge, redirectTo, token } = useGetLoginProps();
  const { goToOnboardingFunnel } = useProvidersearchNavigationHandlers();

  const navigate = useNavigate();
  const toast = useToast();
  const translations = useTranslations();
  usePageMetaData({
    title: () =>
      getProviderSearchTitle(
        translations,
        translations.providersearch.pageTitles.loginPageTitle,
      ),
  });

  usePreventUnload(loggedInState.progress);

  useEffect(() => {
    if (loggedAccount?.id) {
      setLoggedInState({ progress: QUERY_PROGRESS_SUCCEED, error: null });
      return;
    }
    if (!token) {
      console.error(`user in login wizard without token`);
      setLoggedInState({
        progress: QUERY_PROGRESS_FAILED,
        error: REDIRECT_REASON_GENERIC_ERROR,
      });
      return;
    }
    if (!challenge) {
      console.error(`user in login wizard without challenge`);
      setLoggedInState({
        progress: QUERY_PROGRESS_FAILED,
        error: REDIRECT_REASON_GENERIC_ERROR,
      });
      return;
    }

    activateProviderSearch({ token, challenge })
      .then(() => {
        setLoggedInState({ progress: QUERY_PROGRESS_SUCCEED, error: null });
      })
      .catch((err) => {
        if (getErrorMessage(err)?.includes("expired")) {
          setLoggedInState({
            progress: QUERY_PROGRESS_FAILED,
            error: REDIRECT_REASON_LINK_EXPIRED,
          });
          return;
        }

        setLoggedInState({
          progress: QUERY_PROGRESS_FAILED,
          error: REDIRECT_REASON_GENERIC_ERROR,
        });
      });
  }, []);

  switch (loggedInState.progress) {
    case QUERY_PROGRESS_PENDING: {
      return <SpinnerPage size="big" id="providersearchlogin" />;
    }
    case QUERY_PROGRESS_FAILED: {
      const searchString = `?${RETURN_PARAM}=${redirectTo}&${REDIRECT_REASON_PARAM}=${
        loggedInState.error ?? REDIRECT_REASON_GENERIC_ERROR
      }`;
      goToOnboardingFunnel(searchString);
      return null;
    }
    case QUERY_PROGRESS_SUCCEED: {
      navigate({
        pathname: redirectTo,
      });
      toast({
        message: translations.providersearch.loginSuccessful,
        color: "success",
      });
      return null;
    }
    default:
      return null;
  }
}
